import { first, interval } from "rxjs";
import { getElementByClassName, getElementByIdNode } from "../utils";
import {
  STYLES_ID,
  DELTA,
  AGENT_APP_CONTAINER_ID,
  BUTTON_CLOSE_ID,
  STATUS
} from "./constants";
import { dispatchChatEvent } from "./events";
import { aivoChatLauncherElementExists, hide } from "./launcher";
import { getAivo } from "./utils";

export function loadStylesChatbot(): void {
  const agentAppContainerShadowRoot = getElementByIdNode(
    AGENT_APP_CONTAINER_ID,
    document
  )?.shadowRoot;
  if (agentAppContainerShadowRoot) {
    let stylesElementNode = getElementByIdNode(
      STYLES_ID,
      agentAppContainerShadowRoot
    );
    if (!stylesElementNode) {
      stylesElementNode = document.createElement("style");
      stylesElementNode.id = STYLES_ID;
      stylesElementNode.innerHTML = `
      .aivochat-launcher .button-close {
        position: absolute;
        color: #3f48cc !important;
        font-weight: bold !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        height: 16px;
        width: 12px;
        font-family: "NunitoSans";
        border: none;
        background: transparent !important;
        cursor: pointer;
        font-size: 1.5rem !important;
        left: 4rem;
      }
      `;
      agentAppContainerShadowRoot.appendChild(stylesElementNode);
    }
  }
}

function positionDifferencesValidation(
  differenceBetweenXPosition,
  differenceBetweenYPosition
) {
  return (
    differenceBetweenXPosition < DELTA && differenceBetweenYPosition < DELTA
  );
}

export function setAivoChatLauncherElementPosition(): any {
  const agentAppContainerShadowRoot = getElementByIdNode(
    AGENT_APP_CONTAINER_ID,
    document
  )?.shadowRoot;
  if (agentAppContainerShadowRoot) {
    const aivoChatLauncherElement = getElementByIdNode(
      "aivochat-launcher",
      agentAppContainerShadowRoot
    );
    if (aivoChatLauncherElement && getAivo()?.launcher?.position) {
      aivoChatLauncherElement.style.top = getAivo()?.launcher?.position?.top;
      aivoChatLauncherElement.style.left = getAivo()?.launcher?.position?.left;
    }
  }
}

export function createCloseButton(): void {
  const agentAppContainerShadowRoot = getElementByIdNode(
    AGENT_APP_CONTAINER_ID,
    document
  )?.shadowRoot;
  if (agentAppContainerShadowRoot) {
    const aivoChatLauncherElement = getElementByIdNode(
      "aivochat-launcher",
      agentAppContainerShadowRoot
    );
    if (aivoChatLauncherElement) {
      let closeButton = getElementByIdNode(
        BUTTON_CLOSE_ID,
        agentAppContainerShadowRoot
      );
      if (!closeButton) {
        closeButton = document.createElement("button");
        closeButton.id = BUTTON_CLOSE_ID;
        closeButton.classList.add(BUTTON_CLOSE_ID);
        closeButton.innerHTML = "X";
        aivoChatLauncherElement.appendChild(closeButton);
      }
    }
  }
}

export function addEventsToWidget(): void {
  const agentAppContainerShadowRoot = getElementByIdNode(
    AGENT_APP_CONTAINER_ID,
    document
  )?.shadowRoot;
  if (agentAppContainerShadowRoot) {
    const aivoChatLauncherElement = getElementByIdNode(
      "aivochat-launcher",
      agentAppContainerShadowRoot
    );
    if (aivoChatLauncherElement) {
      if (!aivoChatLauncherElement?.hasAttribute("clickListener")) {
        aivoChatLauncherElement.addEventListener("click", (clickEvent) => {
          aivoChatLauncherElement.setAttribute("clickListener", "true");
          clickEvent.stopPropagation();
        });
      }

      let differenceBetweenXPosition;
      let differenceBetweenYPosition;
      //Add drag functionality
      aivoChatLauncherElement.onmousedown = (onMouseDownEvent) => {
        aivoChatLauncherElement.style.position = "fixed";
        let startPositionX = onMouseDownEvent.clientX;
        let startPositionY = onMouseDownEvent.clientY;

        const moveAt = (clientPositionX, clientPositionY) => {
          aivoChatLauncherElement.style.top = `${aivoChatLauncherElement.offsetTop -
            (startPositionY - clientPositionY)
            }px`;
          aivoChatLauncherElement.style.left = `${aivoChatLauncherElement.offsetLeft -
            (startPositionX - clientPositionX)
            }px`;
          startPositionY = clientPositionY;
          startPositionX = clientPositionX;
        };

        const onMouseLeave = () => {
          dispatchChatEvent(aivoChatLauncherElement, "mouseup");
        };
        document.addEventListener("mouseleave", onMouseLeave);

        const onMouseMove = (onMouseMoveEvent) => {
          moveAt(onMouseMoveEvent.clientX, onMouseMoveEvent.clientY);
        };
        document.addEventListener("mousemove", onMouseMove);

        moveAt(onMouseDownEvent.clientX, onMouseDownEvent.clientY);

        aivoChatLauncherElement.onmouseup = (onMouseUpEvent) => {
          differenceBetweenXPosition = Math.abs(
            onMouseUpEvent.clientX - onMouseDownEvent.clientX
          );
          differenceBetweenYPosition = Math.abs(
            onMouseUpEvent.clientY - onMouseDownEvent.clientY
          );
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseleave", onMouseLeave);
          aivoChatLauncherElement.onmouseup = null;
          getAivo().launcher.position = {
            top: aivoChatLauncherElement?.style?.top,
            left: aivoChatLauncherElement?.style?.left,
          };
        };
      };

      //Add listener to maximize widget
      let notificationImage = getElementByClassName(
        "aivochat-launcher__notification-image",
        agentAppContainerShadowRoot
      );
      if (!notificationImage?.hasAttribute("clickListener")) {
        notificationImage?.addEventListener("click", () => {
          notificationImage?.setAttribute("clickListener", "true");
          if (
            positionDifferencesValidation(
              differenceBetweenXPosition,
              differenceBetweenYPosition
            )
          )
            getAivo()?.chat.open();
        });
      }

      //Add event to close button
      let closeButton = getElementByIdNode(
        BUTTON_CLOSE_ID,
        agentAppContainerShadowRoot
      );
      if (!closeButton?.hasAttribute("clickListener")) {
        closeButton?.addEventListener("click", () => {
          closeButton?.setAttribute("clickListener", "true");
          if (
            !positionDifferencesValidation(
              differenceBetweenXPosition,
              differenceBetweenYPosition
            )
          ) return;

          getAivo()?.statusEvent == STATUS.OPEN ? getAivo()?.chat?.close() : hide();
        });
      }
    }
  }
}

export function setMinimizeButtonStylesAndEvents() {
  interval(300)
    .pipe(first(() => aivoChatLauncherElementExists()))
    .subscribe(() => {
      setAivoChatLauncherElementPosition();
      createCloseButton();
      addEventsToWidget();
    });
}
