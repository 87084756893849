export function getElementByIdNode(elementID: string, node: any = document) {
  return node.getElementById(elementID);
}

export function getElementByClassName(
  elementClassName: string,
  node: any = document
) {
  return node.querySelector(`.${elementClassName}`);
}
