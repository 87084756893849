import { IDatasource } from "../interfaces/IDatasource";

let LocalStorageDatasourceInstance: LocalStorageDatasource;

export function getLocalStorageDatasourceInstance(): LocalStorageDatasource {
    if (!LocalStorageDatasourceInstance) {
        LocalStorageDatasourceInstance = new LocalStorageDatasource();
    }
    return LocalStorageDatasourceInstance;
}

class LocalStorageDatasource implements IDatasource<string, string>{

    saveOrUpdateItemById(id: string, data: string): void {
        localStorage.setItem(id, data);
    }
    getItemById(id: string): string {
        return localStorage.getItem(id) ?? "";
    }
    deleteItemById(id: string): void {
        localStorage.removeItem(id);
    }
}