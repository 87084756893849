import { first, interval } from "rxjs";
import { getElementByIdNode } from "../utils";
import { AGENT_APP_CONTAINER_ID, INTERVAL_GENERAL, LAUNCHER_CLASS_ID } from "./constants";
import { getAivo } from "./utils";

export function aivoChatLauncherElementExists(): any {
    const agentAppContainerShadowRoot = getElementByIdNode(
      AGENT_APP_CONTAINER_ID,
      document
    )?.shadowRoot;
    if (agentAppContainerShadowRoot) {
      const aivoChatLauncherElement = getElementByIdNode(
        LAUNCHER_CLASS_ID,
        agentAppContainerShadowRoot
      );
      if (aivoChatLauncherElement) return true;
    }
    return false;
  }

export function show() {
    interval(INTERVAL_GENERAL)
    .pipe(first(() => aivoChatLauncherElementExists()))
    .subscribe(() => {
      getAivo().launcher.show()
    });
}

export function hide() {
    interval(INTERVAL_GENERAL)
    .pipe(first(() => aivoChatLauncherElementExists()))
    .subscribe(() => {
      getAivo().launcher.hide()
    });
}