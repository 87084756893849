import { BehaviorSubject, tap } from "rxjs";
import { KEY_LOCAL_STORAGE_HISTORY_MESSAGES } from "./constants";
import { getLocalStorageDatasourceInstance } from './datasourses/LocalStorageDatasource';
import { getJsonParseInstance } from "./Parsers/JsonParse";

let saveInteractionSubject$: BehaviorSubject<string[]> | null;
const localStorageDatasourceInstance = getLocalStorageDatasourceInstance();
const jsonParseInstance = getJsonParseInstance();

function getHistoryFromLocalStorage(): string[] {
    try {
        const localHistory = localStorageDatasourceInstance.getItemById(KEY_LOCAL_STORAGE_HISTORY_MESSAGES);
        return localHistory ? jsonParseInstance.parse(localHistory) : [];
    } catch (error) {
        console.error('Error getting data from localStorage', error);
        return [];
    }
}

function initSaveInteractionSubject(): BehaviorSubject<string[]> {
    if (!saveInteractionSubject$) {
        saveInteractionSubject$ = new BehaviorSubject<string[]>(getHistoryFromLocalStorage());
    }
    return saveInteractionSubject$;
}

export function subscribeSaveInteraction(): void {
    initSaveInteractionSubject()
        .pipe(
            tap((historyMessages: string[]) => {
                localStorageDatasourceInstance.saveOrUpdateItemById(KEY_LOCAL_STORAGE_HISTORY_MESSAGES, jsonParseInstance.unParse(historyMessages));
            })
        )
        .subscribe();
}

export function unsubscribeSaveInteraction(): void {
    initSaveInteractionSubject().unsubscribe();
    saveInteractionSubject$ = null;
}

export function unsubscribeSaveInteractionAndDeleteLocalStorage(): void {
    unsubscribeSaveInteraction()
    localStorageDatasourceInstance.deleteItemById(KEY_LOCAL_STORAGE_HISTORY_MESSAGES);
}

export function saveInteraction(question: string): void {
    const subject$ = initSaveInteractionSubject();
    const historyMessages = [...subject$.getValue(), question];
    subject$.next(historyMessages);
}
