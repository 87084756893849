export interface IMatchIncludeRulesAivo {
  plural: string[];
  singular: string[];
}

export interface IMatchIncludeRulesAivo {
  [key: string]: string[];
}

export interface IRulesMatchAivo {
  include?: IMatchIncludeRulesAivo;
  exclude?: IMatchIncludeRulesAivo;
}

export interface IConfigurationsRulesAivo {
  token: string;
}

export interface IRulesRulesAivo {
  match: IRulesMatchAivo;
}

export interface IRulesAivo {
  id: number;
  state: string;
  name: string;
  configurations: IConfigurationsRulesAivo;
  rules: IRulesRulesAivo;
}

export interface IUserInformation {
  planType: number;
  country: string;
  name?: string;
  NIT?: string;
  serial?: string;
  prepaidPlanType?: number;
  allowInvoices?: number;
}

export interface IFieldFive9Configuration {
  value: string,
  show: boolean,
  label: string,
  required?: boolean,
}

export interface IFieldsFive9Configuration {
  [key: string]: IFieldFive9Configuration
}

export interface IMaximumActiveTime {
  time: number,
  type: 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years' | 'dates'
}

export interface ISurveyFive9Configuration {
  urlSendSurvey: string
  maximumActiveTime?: IMaximumActiveTime
}

export interface IChatFive9Configuration {
  type: string,
  tenant: string,
  fields: IFieldsFive9Configuration,
  profiles: Array<string>,
  title: string,
  theme: string,
  logo: string,
  lang: string,
  showProfiles: boolean,
  useBusinessHours: boolean,
  survey: ISurveyFive9Configuration,
  profileLabel?: string,
  showEmailButton?: boolean,
  allowCustomerToControlSoundPlay?: boolean,
  playSoundOnMessage?: boolean,
  strict?: boolean
}

export interface IFive9Configuration {
  chat: IChatFive9Configuration
}

export interface IGeneralConfiguration {
  baseUrl: string,
  five9: IFive9Configuration
}

export interface IFeedbackCount {
  good: number;
  bad: number;
}

export interface ILastQuestionInformation {
  type: string;
  question: string;
}

export interface IConfigurationsAivo {
  url: string;
  release: string;
}

export const CONFIGURATIONS_SCHEMA = {
  five9: {
    chat: {
      type: "string",
      tenant: "string",
      fields: "object",
      profiles: "object",
      title: "string",
      theme: "string",
      logo: "string",
      lang: "string",
      showProfiles: "boolean",
      useBusinessHours: "boolean",
      survey: {
        urlSendSurvey: "string"
      }
    }
  }
};

export const ERROR_BASIC_CONFIGURATIONS = "Las configuraciones no cumplen con el esquema";

export enum STATUS {
  OK = "ok",
  OPEN = "open",
  CLOSED = "closed",
}

export enum EVENTS {
  LOAD = "load",
  ERROR = "error",
}

export enum SCRIPT_ELEMENTS {
  SCRIPT = "script",
  ID = "id",
  SRC = "src",
}

export const DELTA = 6;

export const INTERACTION = {
  TYPES: {
    INTENTION: "intention",
    EVASION: "evasion",
    EVASION_GARBAGE: "evasion garabage", // Debería ser "evasion garbage" pero así mal escrito lo devuelve Aivo
  },
};

export const COUNTRIES = {
  EC: {
    CODE: { ALPHA2: "EC" },
    FEEDBACK: {
      PROPERTY: "feedbackCount",
      BAD: {
        ADVISER: {
          MAXIMUM: 2,
        },
      },
    },
    EVENTS: {
      BUTTONS: {
        MESSAGE_CONTACT_ADVISER: "message_contact_adviser_ecuador",
        MESSAGE_SET_SKILL_CUSTOMER_CHAT_FOR_NUBE_OPTION:
          "message_set_skill_Ecu_Customer_Chat_for_Nube_option",
        MESSAGE_SET_SKILL_CUSTOMER_CHAT_FOR_CONTADOR_OPTION:
          "message_set_skill_Ecu_Customer_Chat_for_Contador_option",
        MESSAGE_SET_SKILL_CUSTOMER_POS_CHAT:
          "message_set_skill_Ecu_Customer_Pos_Chat",
        MESSAGE_SET_SKILL_FACTURA_ELECTRONICA_CHAT:
          "message_set_skill_Ecu_FacturaElectronica_Chat",
      },
    },
    MESSAGES: {
      ADVISER: "Comunicarme con un asesor",
      SIIGO_CONTIFICO_NUBE: "Siigo Contífico Nube",
      SIIGO_CONTIFICO_POS: "Siigo Contífico Pos",
      SIIGO_CONTIFICO_FACTURACION_ELECTRONICA:
        "Siigo Contífico Facturación electrónica",
      SIIGO_CONTIFICO_CONTADOR: "Siigo Contífico Contador",
    },
    INTERACTION: {
      CONSECUTIVE_UNANSWERED_QUESTIONS: 2,
    },
  },
  CO: {
    CODE: { ALPHA2: "CO" },
    FEEDBACK: {
      PROPERTY: "feedbackCount",
      BAD: {
        ADVISER: {
          MAXIMUM: 2,
        },
      },
    },
    EVENTS: {
      BUTTONS: {
        MESSAGE_CONTACT_ADVISER: "message_contact_adviser",
        TALK_WITH_ADVISER: "Hablar con un asesor",
      },
    },
    MESSAGES: {
      ADVISER: "Comunicarme con un asesor",
    },
    INTERACTION: {
      CONSECUTIVE_UNANSWERED_QUESTIONS: 2,
    },
  },
};

export const AGENT_APP_CONTAINER_ID: string = "AgentAppContainer";
export const BUTTON_CLOSE_ID: string = "button-close";
export const LAUNCHER_CLASS_ID: string = "aivochat-launcher";
export const STYLES_ID: string = "aivo-styles";
export const ICON_IMAGE: string =
  "https://my.aivo.co/assets/img/cta_icon_FFFFFF.png";
export const SCRIPT_ID: string = "AIVO";
export const BUTTON_EVENT_SEPARATOR: string = "|||";
export const KEY_LOCAL_STORAGE_RULES_AIVO: string = "rulesAivo";
export const KEY_LOCAL_STORAGE_HISTORY_MESSAGES: string = "historyMessagesAivo";
export const EXTERNAL_RESOURCES = {
  RULES: "aivo/rules.json",
  CONFIGURATIONS: "aivo/configurations.json",
};
export const AMOUNT_OF_RETRIES: number = 3;
export const MATCH_INCLUDE_RULES_AIVO: IMatchIncludeRulesAivo = {
  singular: ["serial", "planType"],
  plural: ["serials", "planTypes"],
};
export const INTERVAL_GENERAL: number = 300;
