import { getAivo, aivoChatSendMessage } from "./utils";
import { IFeedbackCount, COUNTRIES } from "./constants";

export function showAdviserButtonForTwoBadFeedbacks(value: boolean): any {
  let country = getAivo()?.country;
  let feedbackCount: IFeedbackCount =
    getAivo()?.user?.get()?.[COUNTRIES[country].FEEDBACK.PROPERTY];
  let countries = [COUNTRIES.EC.CODE.ALPHA2];

  if (value === true) feedbackCount.good++;
  else feedbackCount.bad++;

  switch (true) {
    case countries.includes(country) &&
      feedbackCount.bad >= COUNTRIES.EC.FEEDBACK.BAD?.ADVISER?.MAXIMUM:
    case feedbackCount.bad >= COUNTRIES.CO.FEEDBACK.BAD?.ADVISER?.MAXIMUM:
      aivoChatSendMessage(COUNTRIES[country].MESSAGES.ADVISER);
      feedbackCount.bad = 0;
      break;
    default:
      break;
  }
  getAivo()?.user?.set(COUNTRIES[country].FEEDBACK.PROPERTY, feedbackCount);
}
