import {
  dispatchChatEvent,
  OPEN_FIVE9_CHAT_EVENT,
  CLOSE_FIVE9_CHAT_EVENT,
  INITIALIZE_CONFIGURATION_FIVE9_CHAT_EVENT,
} from "@siigo-backoffice/five9/chat";

export function closeChat(): void {
  dispatchChatEvent(CLOSE_FIVE9_CHAT_EVENT);
}

export function openChat(configurations: any): void {
  if (!configurations)
    throw new Error("Must load the configurations to initialize the chat");
  dispatchChatEvent(INITIALIZE_CONFIGURATION_FIVE9_CHAT_EVENT, configurations);
  dispatchChatEvent(OPEN_FIVE9_CHAT_EVENT);
}
