import {
  BUTTON_EVENT_SEPARATOR,
  STATUS,
  IFeedbackCount,
  COUNTRIES,
  IFive9Configuration,
} from "./constants";
import { showAdviserButtonForTwoBadFeedbacks } from "./feedback-intentions";
import { getAivo, getAivoChat } from "./utils";
import { showAdviserButtonForTwoConsecutiveUnansweredQuestions } from "./interactions";
import {
  saveInteraction,
  subscribeSaveInteraction,
  unsubscribeSaveInteraction,
  unsubscribeSaveInteractionAndDeleteLocalStorage,
} from "./saveInteraction";
import { setMinimizeButtonStylesAndEvents } from "./styles";
import { hide, show } from "./launcher";
import { openChat } from "../five9";
import { OPEN_FIVE9_CHAT_EVENT } from "@siigo-backoffice/five9/chat";

export function subscribeEventButtons(
  five9Configurations: IFive9Configuration
): void {
  getAivo()?.subscribe.onSelectedButton(function (value: string) {
    /* Value llega como un string con dos posibles estructuras
     * la primera consta directamente del mensaje que enviara el botón
     * o una lista de valores separados por "|||" la cual se compone de la
     * siguiente forma: aivo-studio|||message_contact_adviser|||ApiClientCall1657658433
     * donde la opción de la mitad es el nombre del flow que se disparara
     */
    const valueSplit: string[] = value.split(BUTTON_EVENT_SEPARATOR);
    const buttonId: string =
      valueSplit.length > 1 ? valueSplit[1] : valueSplit[0];
    if (!buttonId) return;

    switch (buttonId) {
      case COUNTRIES.CO.EVENTS.BUTTONS.MESSAGE_CONTACT_ADVISER:
      case COUNTRIES.CO.EVENTS.BUTTONS.TALK_WITH_ADVISER:
        getAivo().statusEvent = STATUS.CLOSED;
        openChat(five9Configurations);
        getAivoChat().minimize();
        hide();
        break;
      case COUNTRIES.EC.EVENTS.BUTTONS.MESSAGE_CONTACT_ADVISER:
        getAivo().statusEvent = STATUS.CLOSED;
        getAivo()?.user?.get((userData) => {
          userData?.skill &&
            (five9Configurations.chat.profiles = [userData?.skill]);
          openChat(five9Configurations);
          getAivoChat().minimize();
          hide();
        });
        break;
      case COUNTRIES
        .EC.EVENTS.BUTTONS.MESSAGE_SET_SKILL_CUSTOMER_CHAT_FOR_NUBE_OPTION:
        getAivoChat()?.sendMessage(COUNTRIES.EC.MESSAGES.SIIGO_CONTIFICO_NUBE);
        break;
      case COUNTRIES.EC.EVENTS.BUTTONS.MESSAGE_SET_SKILL_CUSTOMER_POS_CHAT:
        getAivoChat()?.sendMessage(COUNTRIES.EC.MESSAGES.SIIGO_CONTIFICO_POS);
        break;
      case COUNTRIES
        .EC.EVENTS.BUTTONS.MESSAGE_SET_SKILL_FACTURA_ELECTRONICA_CHAT:
        getAivoChat()?.sendMessage(
          COUNTRIES.EC.MESSAGES.SIIGO_CONTIFICO_FACTURACION_ELECTRONICA
        );
        break;
      case COUNTRIES
        .EC.EVENTS.BUTTONS.MESSAGE_SET_SKILL_CUSTOMER_CHAT_FOR_CONTADOR_OPTION:
        getAivoChat()?.sendMessage(
          COUNTRIES.EC.MESSAGES.SIIGO_CONTIFICO_CONTADOR
        );
        break;
      default:
        break;
    }
  });
}

export function openFive9ChatListener(): void {
  window.addEventListener(OPEN_FIVE9_CHAT_EVENT, () => {
    getAivo()?.statusEvent && getAivo()?.statusEvent != STATUS.CLOSED
      ? getAivo()?.chat.close()
      : hide();
  });
}

export function subscribeMinimizeChat(): void {
  getAivo()?.subscribe?.onMinimizeWindow(() => {
    //Mostrar el botón de inicio cuando se minimiza el chat
    show();
    setMinimizeButtonStylesAndEvents();
    unsubscribeSaveInteraction();
  });
}

export function subscribeOpenChat(): void {
  getAivo().subscribe.onOpenWindow(() => {
    getAivo().statusEvent = STATUS.OPEN;
    subscribeSaveInteraction();
  });
}

export function subscribeCloseChat(): void {
  getAivo().subscribe.onCloseWindow(() => {
    getAivo().statusEvent = STATUS.CLOSED;
    //Se oculta botón de inicio de acuerdo a las normas de siigo nube
    hide();
    unsubscribeSaveInteractionAndDeleteLocalStorage();
  });
}

export function subscribeFeedbackIntention(country: string): void {
  let feedbackCount: IFeedbackCount = {
    good: 0,
    bad: 0,
  };
  getAivo()?.user?.set(COUNTRIES[country].FEEDBACK.PROPERTY, feedbackCount);

  getAivo()?.subscribe.onFeedbackIntention(function (value: boolean) {
    showAdviserButtonForTwoBadFeedbacks(value);
  });
}

export function subscribeInteraction(): void {
  getAivo()?.subscribe.onInteraction(function (type: string, question: string) {
    saveInteraction(question);
    showAdviserButtonForTwoConsecutiveUnansweredQuestions(type, question);
  });
}
