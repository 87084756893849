declare global {
  interface Window {
    $aivo: any;
  }
}

export function getAivo(): any {
  return window?.$aivo;
}

export function getAivoChat(): any {
  return getAivo()?.chat;
}

export function aivoChatSendMessage(message: string): any {
  getAivoChat()?.sendMessage(message);
}

/**
 * @function validateObject
 * @description valida campos requeridos en un esquema
 * @return boolean true en caso de tener todos los campos correspondientes y
 *  false de lo contrario
 */
export function validateObject(object: any, schema: any): boolean {
  /** 
   * Se usa for clasico para poder retornar el valor false con la primer iteracion
   * que no cumpla los condicionales
   */
  for (const key in schema) {
    const expectedType = schema[key];

    if (typeof expectedType === "object") {
      // Si el tipo esperado es un objeto, significa que tenemos una propiedad anidada.
      if (!validateObject(object[key], expectedType)) {
        return false;
      }
    } else if (typeof object[key] !== expectedType) {
      return false;
    }
  }

  return true;
}