import axios from "axios";
import {
  delay,
  first,
  from,
  interval,
  map,
  mergeMap,
  Observable,
  of,
  retry,
  Subscriber,
  tap,
  throwError,
} from "rxjs";
import {
  AMOUNT_OF_RETRIES,
  EVENTS,
  IRulesAivo,
  IUserInformation,
  KEY_LOCAL_STORAGE_RULES_AIVO,
  MATCH_INCLUDE_RULES_AIVO,
  SCRIPT_ELEMENTS,
  SCRIPT_ID,
  STATUS,
  IFeedbackCount,
  COUNTRIES,
  IConfigurationsAivo,
  EXTERNAL_RESOURCES,
  IConfigurationsRulesAivo,
  IFive9Configuration,
  IGeneralConfiguration,
  CONFIGURATIONS_SCHEMA,
  ERROR_BASIC_CONFIGURATIONS,
} from "./constants";
import { loadStylesChatbot, setMinimizeButtonStylesAndEvents } from "./styles";
import {
  openFive9ChatListener,
  subscribeCloseChat,
  subscribeEventButtons,
  subscribeFeedbackIntention,
  subscribeMinimizeChat,
  subscribeOpenChat,
  subscribeInteraction,
} from "./suscriptions";
import { getAivo, getAivoChat, validateObject } from "./utils";
import { hide } from "./launcher";
import { closeChat } from "../five9";
import i18nModelInstance, { getTranslation } from "../models/i18n-model";

let feedbackCount: IFeedbackCount = {
  good: 0,
  bad: 0,
};

function verifyScript(): HTMLElement | any {
  return document.getElementById(SCRIPT_ID);
}

export function openBot(): void {
  closeChat();
  getAivo()?.launcher.show();
  getAivoChat()?.open();
}

function setCountry(country): void {
  getAivo().country = country;
}

export function initSubscribes(
  five9Configuration: IFive9Configuration,
  country: string | any
): void {
  subscribeEventButtons(five9Configuration);
  subscribeOpenChat();
  subscribeCloseChat();
  subscribeMinimizeChat();
  subscribeFeedbackIntention(country);
  getAivo()?.user?.set(COUNTRIES[country].FEEDBACK.PROPERTY, feedbackCount);
  subscribeInteraction();
  openFive9ChatListener();
}

/**
 * @function loadScript
 * @description Se genera descarga del  script
 */
function loadScript(scriptUrl: string): Observable<HTMLElement | Error> {
  const scriptAivo: HTMLElement = verifyScript();
  if (scriptAivo || getAivo())
    return of(scriptAivo ?? document.createElement("script"));
  return new Observable((subscriber: Subscriber<any>) => {
    const scriptElement = document.createElement(SCRIPT_ELEMENTS.SCRIPT);
    scriptElement.id = SCRIPT_ID;
    scriptElement.src = scriptUrl;
    scriptElement.addEventListener(EVENTS.LOAD, () => {
      subscriber.next(scriptElement);
      subscriber.complete();
    });
    scriptElement.addEventListener(EVENTS.ERROR, (error) => {
      subscriber.error(error);
    });
    document.body.appendChild(scriptElement);
  });
}
/**
 * @function containtRuleMatch
 * @description  Se obtiene las reglas
 */
function containtRuleMatch(
  value?: string[],
  userInformation?: string
): boolean {
  if (value?.find((_value: string) => _value == userInformation)) return true;
  return false;
}

/**
 * @function getScriptUrl
 * @description  Se genera llamada para la descarga del script del bot
 */
function getScriptUrl(_configurations: IConfigurationsAivo): string {
  return `${_configurations.url}?${_configurations.release}`;
}

/**
 * @function getRule
 * @description  Se genera llamada para filtrar la regla del usuario
 */
function getRule(
  _rules: IRulesAivo[],
  _userInformationAivo: IUserInformation
): IRulesAivo {
  let aivoRule: any;
  /**
   * Se usa for tradicional para hacer uso de break en caso de encontrar
   * una condición que se cumpla
   */
  for (
    let index: number = 0;
    index < MATCH_INCLUDE_RULES_AIVO.plural.length;
    index++
  ) {
    aivoRule = _rules?.find((rule: IRulesAivo) => {
      const hasContaintRuleMatchInclude: boolean = containtRuleMatch(
        rule?.rules?.match?.include?.[MATCH_INCLUDE_RULES_AIVO.plural[index]],
        _userInformationAivo[MATCH_INCLUDE_RULES_AIVO.singular[index]]
      );
      const hasContaintRuleMatchExclude: boolean = containtRuleMatch(
        rule?.rules?.match?.exclude?.[MATCH_INCLUDE_RULES_AIVO.plural[index]],
        _userInformationAivo[MATCH_INCLUDE_RULES_AIVO.singular[index]]
      );

      /**
       * Se valida si el usuario cumple las condicionales de las reglas:
       * hasContaintRuleMatchInclude: Si el usuario cumple con
       * el include se arroja un true
       * hasContaintRuleMatchExclude: Si el usuario cumple con
       * el exclude se arroja un true pero se
       * niega la condicional ya que el usuario no puede
       * aplicar a esa regla.
       */
      if (hasContaintRuleMatchInclude && !hasContaintRuleMatchExclude)
        return true;
    });
    if (aivoRule) break;
  }

  return aivoRule;
}

/**
 * @function getRuleConfigurations
 * @description Se obtienen las configuraciones de la regla
 */
function getRuleConfigurations(rule: IRulesAivo): IConfigurationsRulesAivo {
  return rule?.configurations;
}

/**
 * @function getConfigurationsScript
 * @description Se obtienen las configuraciones principales para la descarga script del bot
 */
function getConfigurationsScript(_baseUrl): Observable<IConfigurationsAivo> {
  return from(
    axios.get(`${_baseUrl}${EXTERNAL_RESOURCES.CONFIGURATIONS}`, {
      headers: {
        // Se agrega cabecera para evitar que los interceptores del frontManager le agreguen token a la petición
        ignoreheaders: "",
      },
    })
  ).pipe(
    retry(AMOUNT_OF_RETRIES),
    map(({ data }: any) => data)
  );
}
/**
 * @function getRulesScript
 * @description Se obtienen las reglas principales para la descarga script del bot
 */
function getRulesScript(baseUrl: string): Observable<IRulesAivo[]> {
  try {
    let rulesAivo: string | null = localStorage.getItem(
      KEY_LOCAL_STORAGE_RULES_AIVO
    );
    if (rulesAivo) return of(JSON.parse(rulesAivo));
    return from(
      axios.get(`${baseUrl}${EXTERNAL_RESOURCES.RULES}`, {
        headers: {
          // Se agrega cabecera para evitar que los interceptores del frontManager le agreguen token a la petición
          ignoreheaders: "",
        },
      })
    ).pipe(
      retry(AMOUNT_OF_RETRIES),
      map(({ data }: any) => data),
      tap((_rules: any) => {
        localStorage.setItem(
          KEY_LOCAL_STORAGE_RULES_AIVO,
          JSON.stringify(_rules)
        );
      })
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * @function ready
 * @description Configuraciones iniciales de aivo
 */
export function ready(
  _userInformationAivo: IUserInformation,
  _generalConfiguration?: any
): Observable<any> {
  if (!validateObject(_generalConfiguration, CONFIGURATIONS_SCHEMA) || !_generalConfiguration.baseUrl)
    return throwError(() => new Error(ERROR_BASIC_CONFIGURATIONS));
  const generalConfiguration = _generalConfiguration as IGeneralConfiguration
  i18nModelInstance.initializeI18n(_generalConfiguration?.language);
  return getConfigurationsScript(generalConfiguration.baseUrl).pipe(
    map(getScriptUrl),
    mergeMap(loadScript),
    /**
     * Delay para retrasar el ingreso al suscribe debido a la demora producida
     * por la asignación de la variable global window.$aivo generada por el
     * código descargado de AIVO.
     */
    delay(2000),
    mergeMap(() => getRulesScript(generalConfiguration.baseUrl)),
    map((_rules) => {
      const rule = getRule(_rules, _userInformationAivo);
      return getRuleConfigurations(rule);
    }),
    tap((ruleConfigurations) => {
      getAivo()?.run(ruleConfigurations?.token);
      getAivo()?.shadowRoot(false);
    }),
    mergeMap(() => {
      return new Observable((subscriber: Subscriber<any>) => {
        if (getAivo()?.status == STATUS.OK) {
          subscriber.next(getAivo());
          subscriber.complete();
          return;
        }

        getAivo()?.ready(function () {
          if (getAivo()?.status != STATUS.OK)
            subscriber.error(new Error("Error ready aivo!"));

          getAivo()?.user?.set(_userInformationAivo);
          subscriber.next(getAivo());
          subscriber.complete();
        });
      });
    }),
    tap(() =>
      initSubscribes(
        generalConfiguration.five9,
        _userInformationAivo?.country
      )
    ),
    mergeMap((aivoBot) => interval(500).pipe(first(() => aivoBot?.chat))),
    tap(() => {
      loadStylesChatbot();
      setCountry(_userInformationAivo?.country);
    }),
    tap(() => setMinimizeButtonStylesAndEvents()),
    tap(() => hide())
  );
}
