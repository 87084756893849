import { IParser } from "../interfaces/IParser";

let JsonParseInstance: JsonParse;

export function getJsonParseInstance(): JsonParse {
    if (!JsonParseInstance) {
        JsonParseInstance = new JsonParse();
    }
    return JsonParseInstance;
}

class JsonParse implements IParser<string, any>{
    parse(data: string) {
        return JSON.parse(data);
    }
    unParse(data: any): string {
        return JSON.stringify(data);
    }
}