import { aivoChatSendMessage, getAivo } from "./utils";
import { INTERACTION, ILastQuestionInformation, COUNTRIES, KEY_LOCAL_STORAGE_HISTORY_MESSAGES } from "./constants";

let lastQuestionInformation: ILastQuestionInformation,
  evasionCount: number = 0;

export function showAdviserButtonForTwoConsecutiveUnansweredQuestions(
  type: string,
  question: string
): any {
  if (type === INTERACTION.TYPES.EVASION) evasionCount++;

  if (
    evasionCount >= COUNTRIES[getAivo()?.country].INTERACTION.CONSECUTIVE_UNANSWERED_QUESTIONS &&
    type === INTERACTION.TYPES.EVASION &&
    lastQuestionInformation?.type === INTERACTION.TYPES.EVASION
  ) {
    aivoChatSendMessage(COUNTRIES[getAivo()?.country].MESSAGES.ADVISER);
    evasionCount = 0;
  }

  lastQuestionInformation = {
    type: type,
    question: question,
  };
}